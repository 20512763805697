<template>
	<v-row>
		<v-col cols="12" class="mt-n3">
			<v-card class="pa-5">
				<div class="text-center">
					<h2 class="mb-4 primary--text">SEO Score</h2>
					<v-progress-circular
						:rotate="-90"
						:size="150"
						:width="25"
						:value="totalScore"
						:color="totalScoreColor"
						class="scoreTitle"
					>
						{{ totalScore }}
					</v-progress-circular>
				</div>

				<div class="mt-4 text-center"><v-divider></v-divider></div>

				<p v-if="error" class="error--text my-4">{{ error }}</p>
				<h3 class="mt-8 mb-2 primary--text">Focus Keywords</h3>
				<v-combobox
					outlined
					dense
					multiple
					v-model="keywords"
					append-icon
					chips
					deletable-chips
					label="Enter Keywords"
				>
				</v-combobox>

				<h3 class="mb-2 primary--text">Meta Title</h3>
				<v-text-field
					label="Enter a page title"
					outlined
					dense
					required
					v-model="metaTitle"
				></v-text-field>
				<template>
					<v-progress-linear
						:value="score.metaTitle"
						:color="metaTitleScoreColor"
					></v-progress-linear>
				</template>
				<ul class="mt-2">
					<li
						v-for="(suggestion, i) in metaTitleSuggestions"
						:key="i"
					>
						{{ suggestion }}
					</li>
				</ul>

				<h3 class="mt-5 mb-2 primary--text">Meta Description</h3>
				<v-text-field
					label="Enter a Meta Description"
					outlined
					dense
					required
					v-model="metaDescription"
				>
				</v-text-field>
				<template>
					<v-progress-linear
						:value="score.metaDescription"
						:color="metaDescriptionScoreColor"
					></v-progress-linear>
				</template>

				<ul class="mt-2">
					<li
						v-for="(suggestion, i) in metaDescriptionSuggestions"
						:key="i"
					>
						{{ suggestion }}
					</li>
				</ul>

				<h3 class="my-4 primary--text">Content</h3>
				<template>
					<v-progress-linear
						:value="score.body"
						:color="bodyScoreColor"
					></v-progress-linear>
				</template>
				<ul class="mt-5">
					<li v-for="(suggestion, i) in pageBodySuggestions" :key="i">
						{{ suggestion }}
					</li>
				</ul>
			</v-card>
		</v-col>

		<!-- <v-col class="mt-n10">
			<v-card class="pa-5">
				<h2 class="my-4 primary--text">Other Website Factors</h2>

				<h4 class="mb-2">Page URL:</h4>
				<v-text-field label="Enter the Page URL" outlined dense>
				</v-text-field>

				<h4 class="mb-2">Leads:</h4>
				<v-text-field label="Enter Page Name" outlined dense>
				</v-text-field>

				<h4 class="mb-2">Redirect To:</h4>
				<v-text-field
					label="Enter a page redirection URL"
					outlined
					dense
				>
				</v-text-field>
			</v-card>
		</v-col> -->
	</v-row>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
	data() {
		return {
			keywords: [],
			metaTitle: '',
			metaDescription: '',
			totalScore: 0,
			score: {
				metaTitle: 0,
				metaDescription: 0,
				body: 0,
			},
			metaTitleSuggestions: [],
			metaDescriptionSuggestions: [],
			pageBodySuggestions: [],
			error: '',
		}
	},
	props: {
		urlToSearch: {
			type: String,
			required: true,
		},
		body: {
			type: String,
			required: true,
		},
	},
	watch: {
		keywords: debounce(function () {
			// if (this.keywords.length > 0) {
			this.analyzePageSeo()
			// }
		}, 1000),
		metaTitle: debounce(function () {
			if (this.keywords.length > 0) {
				this.analyzePageSeo()
			}
		}, 1000),
		metaDescription: debounce(function () {
			if (this.keywords.length > 0) {
				this.analyzePageSeo()
			}
		}, 1000),
		body: debounce(function () {
			if (this.keywords.length > 0) {
				this.analyzePageSeo()
			}
		}, 2000),
	},
	created() {
		this.analyzePageSeo()
	},
	computed: {
		totalScoreColor() {
			let color = ''
			if (this.totalScore <= 35) {
				color = 'red'
			}
			if (this.totalScore > 35 && this.totalScore <= 70) {
				color = 'orange'
			}
			if (this.totalScore > 70) {
				color = 'green'
			}
			return color
		},
		metaTitleScoreColor() {
			let color = ''
			if (this.score.metaTitle <= 60) {
				color = 'red'
			}
			if (this.score.metaTitle > 60 && this.score.metaTitle <= 85) {
				color = 'orange'
			}
			if (this.score.metaTitle > 85) {
				color = 'green'
			}
			return color
		},
		metaDescriptionScoreColor() {
			let color = ''
			if (this.score.metaDescription <= 60) {
				color = 'red'
			}
			if (
				this.score.metaDescription > 60 &&
				this.score.metaDescription <= 85
			) {
				color = 'orange'
			}
			if (this.score.metaDescription > 85) {
				color = 'green'
			}
			return color
		},
		bodyScoreColor() {
			let color = ''
			if (this.score.body <= 45) {
				color = 'red'
			}
			if (this.score.body > 45 && this.score.body <= 75) {
				color = 'orange'
			}
			if (this.score.body > 75) {
				color = 'green'
			}
			return color
		},
	},
	methods: {
		setSeoData(data) {
			this.metaTitle = data.pageTitle
			this.metaDescription = data.description
			this.keywords = data.keywords
		},
		clearSeoData() {
			this.metaTitle = null
			this.metaDescription = null
			this.keywords = []
		},
		analyzePageSeo() {
			const projectId = this.$store.state.app.projectId
			// this.axios
			// 	.get(`/${projectId}/pages/search`, {
			// 		url: `${this.urlToSearch}`,
			// 	})
			// 	.then((res) => {
			// 		if (res.status == 200 && res.data.success) {
			// 			let data = res.data.data
			// 			this.setSeoData(data)
			// 			this.errorMessages = null
			// 		} else {
			// 			this.errorMessages = 'This page is not available'
			// 		}
			// 	})
			// 	.catch((error) => {
			// 		console.log(error)
			// 		this.isSaving = false
			// 		this.clearSeoData()
			// 	})

			this.axios
				.post(`/${projectId}/page-audit`, {
					keywords: this.keywords,
					title: this.metaTitle,
					description: this.metaDescription,
					body: this.body,
				})
				.then((res) => {
					this.error = res.data.suggestions.error
					// Score
					this.totalScore = res.data.totalScore ?? 0
					this.score.metaTitle = res.data.score.pageTitle ?? 0
					this.score.metaDescription = res.data.score.description ?? 0
					this.score.body = res.data.score.body ?? 0

					// Suggestions
					this.metaTitleSuggestions = res.data.suggestions.pageTitle
					this.metaDescriptionSuggestions =
						res.data.suggestions.description
					this.pageBodySuggestions = res.data.suggestions.body
				})
				.catch((err) => {
					console.log(err.response.data)
				})
		},
	},
}
</script>
<style>
li {
	list-style-type: disc;
	color: #888888;
}
.scoreTitle {
	font-size: 35px !important;
	font-weight: 500 !important;
}
</style>
