<template>
	<v-container
		v-if="$can('create', 'Service') || $can('update', 'Service')"
		fluid
	>
		<v-row no-gutters>
			<v-col cols="8" class="pr-2">
				<v-card v-if="!loading" class="form-card">
					<v-card-text>
						<p class="ma-0 label">Banner Image:</p>
						<FileUploader
							:initFiles="banner ? [banner] : []"
							@errorOccured="dropzoneError"
							:purpose="`banner`"
							:maxFiles="1"
							@changed="fileUploaderChanged"
						/>

						<v-col cols="12">
							<p class="ma-0 label">Image Alt Text:</p>
							<v-text-field
								v-model="altText"
								placeholder="Image Alternative Text"
								:error-messages="errors.altText"
							></v-text-field>
						</v-col>

						<v-row class="pa-0 mb-0">
							<v-col cols="6">
								<p class="ma-0 label">Name* :</p>
								<v-text-field
									v-model="name"
									placeholder="Service Name"
									@input="$v.name.$touch()"
									@blur="$v.name.$touch()"
									:error-messages="nameErrors()"
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<p class="ma-0 label">Common Name:</p>
								<v-text-field
									v-model="commonName"
									placeholder="Common Name"
								></v-text-field>
							</v-col>

							<v-col cols="12">
								<p class="ma-0 label">Title* :</p>
								<v-text-field
									v-model="title"
									placeholder="Ex: Dental Implant in Nungambakkam, Chennai"
									@input="$v.title.$touch()"
									@blur="$v.title.$touch()"
									:error-messages="titleErrors()"
								></v-text-field>
							</v-col>

							<v-col cols="12">
								<p class="ma-0 label">Brief Description* :</p>

								<Editor
									:initData="description"
									:purpose="`service description`"
									@changed="descriptionEditorChanged"
								></Editor>
								<span
									class="error-msg"
									v-for="(error, ind) in descriptionErrors()"
									:key="ind"
								>
									{{ error }}
								</span>
							</v-col>

							<v-col cols="12">
								<p class="ma-0 label">Content* :</p>
								<div id="ckcontent">
									<Editor
										:initData="content"
										:purpose="`create service`"
										@changed="contentEditorChanged"
									></Editor>
								</div>
								<span
									class="error-msg"
									v-for="(error, ind) in contentErros()"
									:key="ind"
								>
									{{ error }}
								</span>
							</v-col>
						</v-row>

						<p class="ma-0 label">Icon/Logo:</p>
						<FileUploader
							:initFiles="icon ? [icon] : []"
							@errorOccured="dropzoneError"
							:purpose="`treatment icon`"
							:maxFiles="1"
							@changed="iconUploaded"
						/>
					</v-card-text>

					<v-card-actions class="pa-6 card-actions">
						<UnoSaveButton
							@click.native="validateData()"
							@primary-button-click="serviceListing()"
							primary-button-text="Move To Service Listing"
							secondary-button-text="Continue Editing"
							:alert="alert"
							:saving="isSaving"
						></UnoSaveButton>

						<UnoPublishButton
							v-if="isEdit"
							:isPublished="isPublished"
							@click.native="toggleStatus()"
						></UnoPublishButton
						><v-spacer></v-spacer>

						<UnoDeleteButton
							v-if="isEdit"
							@confirm="deleteService()"
						></UnoDeleteButton>
					</v-card-actions>
				</v-card>
			</v-col>

			<v-col cols="4">
				<PageSeoAudit :url-to-search="publicPath" :body="content" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import _ from 'lodash'
import FileUploader from '@/components/FileUploader'
import UnoPublishButton from '@/components/Buttons/UnoPublishButton'
import UnoSaveButton from '@/components/Buttons/UnoSaveButton'
import UnoDeleteButton from '@/components/Buttons/UnoDeleteButton'
import Editor from '@/components/CkEditor'
import PageSeoAudit from '@/components/PageSeoAudit'

export default {
	data() {
		return {
			name: '',
			altText: '',
			description: '',
			content: '',
			commonName: null,
			isSaving: false,
			loading: true,
			serviceId: null,
			banner: null,
			bannerId: null,
			icon: null,
			iconId: null,
			purpose: '',
			publicPath: '',
			isEdit: false,
			isPublished: true,
			title: null,
			alert: {
				show: null,
				message: null,
				error: false,
			},
			errors: {
				name: '',
				altText: '',
				description: '',
				content: '',
				title: '',
			},
		}
	},
	validations: {
		name: {
			required,
			minLength: minLength(2),
		},
		description: {
			required,
			minLength: minLength(12),
		},
		content: {
			required,
			minLength: minLength(12),
		},
		title: {
			required,
			minLength: minLength(5),
		},
	},
	components: {
		UnoDeleteButton,
		UnoPublishButton,
		UnoSaveButton,
		FileUploader,
		Editor,
		PageSeoAudit,
	},
	props: {
		openSidebar: {
			type: Boolean,
		},
		id: {
			type: String,
			default: null,
		},
	},
	watch: {
		description() {
			this.errors.description = ''
		},
		name() {
			this.errors.name = ''
		},
		content() {
			this.errors.content = ''
		},
		altText() {
			this.altTextChanged()
		},
	},
	created() {
		if (this.id) {
			this.isEdit = true
			this.serviceId = this.id
			this.getService()
		} else {
			this.loading = false
		}

		// debounce registering
		this.debouncedAltTextChanged = _.debounce(this.altTextChanged, 500)
	},
	methods: {
		nameErrors() {
			const errors = []
			if (!this.$v.name.$dirty) return errors

			!this.$v.name.required && errors.push('Name is required')
			!this.$v.name.minLength &&
				errors.push('Name should contain atleast 2 letters')
			return errors
		},
		descriptionErrors() {
			const errors = []
			if (!this.$v.description.$dirty) return errors

			!this.$v.description.required &&
				errors.push('Description is required')
			!this.$v.description.minLength &&
				errors.push('Description should contain atleast 5 letters')
			return errors
		},
		contentErros() {
			const errors = []
			if (!this.$v.content.$dirty) return errors

			!this.$v.content.required && errors.push('Content is required')
			!this.$v.content.minLength &&
				errors.push('Content should contain atleast 5 letters')
			return errors
		},
		titleErrors() {
			const errors = []
			if (!this.$v.title.$dirty) return errors

			!this.$v.title.required && errors.push('Title is required')
			!this.$v.title.minLength &&
				errors.push('Title should contain atleast 5 letters')
			return errors
		},
		altTextChanged() {
			let text = this.altText
			if ((this.bannerId && this.altText !== '') || null) {
				this.axios({
					method: 'patch',
					url: `/media/${this.bannerId}`,
					data: { altText: text },
				})
					.then((res) => {
						if (res.status === 404) {
							console.log(404)
						}

						if (res.status == 200 && res.data.success) {
							this.alert.message = res.data.message
						}
					})
					.catch((error) => {
						let errors = error.response.data
						this.alert.message = errors.message
					})
			}
		},
		setServiceData(service) {
			this.altText =
				service.banner && service.banner.altText
					? service.banner.altText
					: null
			this.banner =
				service.banner && service.banner.media ? service.banner : null
			this.bannerId =
				service.banner && service.banner.media
					? service.banner.media
					: null
			this.icon = service.icon && service.icon.media ? service.icon : null
			this.iconId =
				service.icon && service.icon.media ? service.icon.media : null
			this.serviceId = service.id
			this.name = service.name
			this.description = service.description
			this.content = service.content
			this.commonName = service.commonName
			this.isPublished = service.isPublished
			this.title = service.title ? service.title : null
			this.publicPath = service.publicPath
			this.loading = false
		},
		validateData() {
			let isValid = false
			if (this.altText !== '') {
				isValid = true
			} else {
				this.errors.altText =
					'Please enter alternative text for the image.'
			}
			if (this.description !== '') {
				isValid = true
			} else {
				this.errors.description = 'Please enter a brief description.'
			}
			if (this.content !== '') {
				isValid = true
			} else {
				this.errors.content = 'Please enter Content.'
			}
			if (this.title !== '') {
				isValid = true
			} else {
				this.errors.title = 'Please enter Title.'
			}
			if (isValid) {
				this.saveData()
			}
		},
		saveData() {
			this.isSaving = true
			const isEdit = this.isEdit
			const projectId = this.$store.state.app.projectId

			this.altTextChanged() // when user upload banner later

			const payload = {
				name: this.name,
				description: this.description,
				commonName: this.commonName,
				banner: this.bannerId,
				content: this.content,
				icon: this.iconId,
				title: this.title ? this.title : null,
			}
			this.axios({
				method: isEdit ? 'put' : 'post',
				url: isEdit
					? `/${projectId}/services/${this.serviceId}`
					: `/${projectId}/services`,
				data: payload,
			})
				.then((response) => {
					this.isSaving = false

					if (response.status == 200 && response.data.success) {
						const data = response.data.data
						this.isEdit = true
						this.setServiceData(data)
						this.alert.message = response.data.message
					} else {
						//
					}
				})
				.catch((error) => {
					const { data } = error.response
					if (data.statusCode == 400) {
						this.isSaving = false
						this.$snackbar.notify({
							message: data.message,
							color: 'black',
						})
					}
				})
		},
		getService() {
			if (this.isEdit) {
				this.isSaving = true

				const projectId = this.$store.state.app.projectId
				this.axios({
					method: 'get',
					url: `/${projectId}/services/${this.serviceId}`,
				}).then((response) => {
					let data = response.data.data
					this.setServiceData(data)
					this.isSaving = false
				})
			}
		},
		dropzoneError(val) {
			console.log(val)
		},
		contentEditorChanged(body) {
			this.$v.content.$touch()
			this.content = body
		},
		descriptionEditorChanged(body) {
			this.$v.description.$touch()
			this.description = body
		},
		deleteService() {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'delete',
				url: `/${projectId}/services/${this.serviceId}`,
			}).then(() => {
				this.$router.push({ name: 'service.list' })
			})
		},
		toggleStatus() {
			const projectId = this.$store.state.app.projectId

			this.axios({
				method: 'post',
				url: `/${projectId}/services/toggle-status/${this.serviceId}`,
			})
				.then((response) => {
					let _data = response.data
					if (_data.success) {
						this.isPublished = !this.isPublished
					} else if ('errors' in _data) {
						console.log(_data.errors)
					}
				})
				.catch((error) => {
					const { data } = error.response
					if (data.statusCode == 400) {
						this.isSaving = false
						this.$snackbar.notify({
							message: data.message,
							color: 'black',
						})
					}
				})
		},
		fileUploaderChanged(val) {
			if (val.length != 0) {
				const data = val[0]
				this.bannerId = data.id
				this.purpose = 'banner'
				this.banner = {
					id: this.bannerId,
					publicURL: data.publicURL,
				}
			} else {
				this.bannerId = null
				this.banner = null
			}
		},
		iconUploaded(val) {
			if (val.length != 0) {
				const data = val[0]
				this.iconId = data.id
				this.purpose = 'icon'
				this.icon = {
					id: this.iconId,
					publicURL: data.publicURL,
				}
			} else {
				this.iconId = null
				this.icon = null
			}
		},
		serviceListing() {
			this.$router.push({
				name: 'service.list',
			})
		},
	},
}
</script>

<style scoped>
.label {
	font-weight: 500;
	font-size: 1rem;
}
</style>
